import React from "react";
import "./footer.css";
import Logo from "../.././images/home/collegeLogo.png";
import FacebookIcon from "../.././images/home/facebookIcon.png";
import TwitterIcon from "../.././images/home/twitterIcon.png";
import { BsEnvelope } from "react-icons/bs";
import { FiPhone } from "react-icons/fi";

function Footer() {
  return (
    <>
      <div className="container-fluid bg-secondaryColor p-100">
        <div className="container text-white">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 font-24">
              <p className="font-24 font-bold">Important Links</p>
              <div className="d-flex flex-column justify-content-center color font-regular">
                <div className="font-16 mt-4">Dr.A.P.J. AKTU, Lucknow</div>
                <div className="font-16 mt-lg-4 mt-2">Infoconnect</div>
                <div className="font-16 mt-lg-4 mt-2">On-Campus/Of-Campus</div>
                <div className="font-16 mt-lg-4 mt-2">Grievance</div>
                <div className="font-16 mt-lg-4 mt-2">Events</div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 font-24 mt-5 color font-regular">
              <div className="font-16 mt-lg-4 mt-2">IIC</div>
              <div className="font-16 mt-lg-4 mt-2">Computer Center</div>
              <div className="font-16 mt-lg-4 mt-2">Alumni Survey</div>
              <div className="font-16 mt-lg-4 mt-2">PMKVY</div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 font-20 mt-lg-0 mt-5">
              <p className="font-24 font-bold">People</p>
              <div className="d-flex flex-column justify-content-center color font-regular">
                <div className="font-16 mt-lg-4 mt-2 mt-4">Alumni Survey</div>
                <div className="font-16 mt-lg-4 mt-2">Alumni</div>
                <div className="font-16 mt-lg-4 mt-2">Women Cell</div>
              </div>
            </div>
          </div>
          <hr className="w-100" />
          <div className="row mt-5">
            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-center align-items-center ">
                  <img src={Logo} alt="college_logo" className="img-fluid " />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                  <p className="font-24 font-bold ml-lg-2 ml-0">
                    JSS Academy Of Technical Education C-20/1, Sector-62, NOIDA,
                    DISTT.U.P., INDIA-201301
                  </p>
                </div>
              </div>
              {/* <div className="d-flex justify-content-around">
                  <img
                    src={Logo}
                    alt="college_logo"
                    className="img-fluid "
                  />
                  <p className="font-24 font-bold mt-3 ml-5">
                    JSS Academy Of Technical Education C-20/1, Sector-62, NOIDA,
                    DISTT.U.P., INDIA-201301
                  </p>
                </div> */}
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="d-flex align-items-baseline">
                <FiPhone className="font-25" />
                <p className="font-16 mt-lg-3 mt-2 py-0 font-regular">
                  &nbsp;&nbsp;
                  <a href="tel:0120-2400104" className="color">
                    0120-2400104
                  </a>
                  ,{" "}
                  <a href="tel:0120-2400115" className="color">
                    2400115
                  </a>
                </p>
              </div>
              <div className="d-flex">
                <BsEnvelope className="font-25" />
                <a
                  href="mailto:principal@jssaten.ac.in"
                  className="font-16 py-0 font-regular color"
                >
                  &nbsp;&nbsp;principal@jssaten.ac.in
                </a>
              </div>
            </div>
          </div>
          <hr className="w-100 mt-5" />
          <div className="d-flex justify-content-between mt-5">
            <div className="font-14 color font-light">
              Site Map &nbsp; | &nbsp; &copy; JSSATEN - All right reserved.
            </div>
            <div>
              <img
                src={FacebookIcon}
                alt="facebook_icon"
                className="img-fluid mr-4"
              />
              <img src={TwitterIcon} alt="twitter_icon" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
