import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
// import Logo from "../.././images/home/logoSmall.png";
import { Link } from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./navbar.css";

function NavbarComponent2() {
    return (
      <div className="text-light">
        <Navbar expand="xl" variant="light" className="bg-backgroundColor mb-3">
          <Nav className="container font-12 text-nowrap text-center" defaultActiveKey="/">
            <Nav.Link as={Link} to="/">
              <div className="text-secondaryColor font-regular font-12">HOME</div>
            </Nav.Link>
            <NavDropdown
              title="ABOUT US"
              className="my-0"
              id="basic-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  href="/institute"
                >
                  THE INSTITUTE
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/visionmission"
                >
                  VISION &amp; MISSION
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/mandatorydisclosure"
                >
                  MANDATORY DISCLOSURE
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/governingcounsil"
                >
                  GOVERNING COUNCIL
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/antiragging"
                >
                  ANTI RAGGING GUIDELINES
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/staffdirectory"
                >
                  STAFF DIRECTORY
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/importantfunctionaries"
                >
                  INSTITUTE IMPORTANT FUNCTIONARIES
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/awards"
                >
                  AWARDS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/accreditation"
                >
                  ACCREDATION
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item"
                  as={Link}
                  to="/principalmessage"
                >
                  PRINCIPAL MESSAGE
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <NavDropdown
              title="CAMPUS FACILITIES"
              className="my-0"
              id="basic-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">                
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/campusFacilities/library"
                >
                  LIBRARY
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/campusFacilities/computerCentre"
                >
                  COMPUTER CENTRE
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/campusFacilities/mph"
                >
                  MULTIPURPOSE HALL
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/campusFacilities/sports"
                >
                  SPORTS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/campusFacilities/hostelFacilities"
                >
                  HOSTEL FACILITIES
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/campusFacilities/mess"
                >
                  MESS &amp; CAFETERIA
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item"
                  as={Link}
                  to="/campusFacilities/medicalFacilities"
                >
                  MEDICAL FACILITIES
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <NavDropdown
              title="ACADEMICS"
              className="my-0"
              id="basic-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-12 py-2">
                <div className="mb-0">
                  <DropdownButton
                    key="right"
                    id="dropdown-button-drop-right"
                    variant=""
                    drop="right"
                    title="DEPARTMENTS"
                    className="font-regular dropdown-item-bottom"
                  >
                    <div className="bg-secondaryColor text-white font-12 p-2">
                      <NavDropdown.Item
                        className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                        as={Link}
                        to="/department/cse"
                      >
                        Computer Science and Engineering
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                        as={Link}
                        to="/department/it"
                      >
                        Information Technology
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                        as={Link}
                        to="/department/ece"
                      >
                        Electronics and Computer Engineering
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                        as={Link}
                        to="/department/ee"
                      >
                        Electrical Engineering
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-white font-regular font-12 pt-2 dropdown-item dropdown-item-bottom"
                        as={Link}
                        to="/department/eee"
                      >
                        Electrical and Electronics Engineering
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-white font-regular font-12 pt-2 dropdown-item dropdown-item-bottom"
                        as={Link}
                        to="/department/me"
                      >
                        Mechanical Engineering
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-white font-regular font-12 pt-2 dropdown-item dropdown-item-bottom"
                        as={Link}
                        to="/department/ce"
                      >
                        Civil Engineering
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-white font-regular font-12 pt-2 dropdown-item dropdown-item-bottom"
                        as={Link}
                        to="/department/ice"
                      >
                        Instrumentation and Control Engineering
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-white font-regular font-12 pt-2 dropdown-item dropdown-item-bottom"
                        as={Link}
                        to="/department/mba"
                      >
                        Masters of Business Administration
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-white font-regular font-12 pt-2 dropdown-item dropdown-item-bottom"
                        as={Link}
                        to="/department/mca"
                      >
                        Masters of Computer Applications
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-white font-regular font-12 pt-2 dropdown-item dropdown-item-bottom"
                        as={Link}
                        to="/department/phy"
                      >
                        Physics
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-white font-regular font-12 pt-2 dropdown-item dropdown-item-bottom"
                        as={Link}
                        to="/department/chem"
                      >
                        Chemistry
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-white font-regular font-12 pt-2 dropdown-item dropdown-item-bottom"
                        as={Link}
                        to="/department/mat"
                      >
                        Maths
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-white font-regular font-12 pt-2 dropdown-item"
                        as={Link}
                        to="/department/eng"
                      >
                        English
                      </NavDropdown.Item>
                    </div>
                  </DropdownButton>
                </div>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom py-2"
                  as={Link}
                  to="/"
                >
                  ACHIEVEMENTS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  FACULTY
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  ACADEMIC CALENDER
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  GRIEVANCE
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  DEAN STUDENT WELFARE
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 pt-2 dropdown-item"
                  as={Link}
                  to="/"
                >
                  DEAN ACADEMICS
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <NavDropdown
              title="TRAINING &amp; PLACEMENT"
              className="my-0"
              id="basic-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/training&amp;Placement/overview"
                >
                  OVERVIEW
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/training&amp;Placement/tpoMessage"
                > 
                  TPO MESSAGE
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/training&amp;Placement/recruits"
                >
                  RECRUITERS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/training&amp;Placement/policy"
                >
                  POLICY
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/training&amp;Placement/placedStatistics"
                >
                  PLACED STATISTICS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  TOP PLACEMENTS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 pt-2 dropdown-item"
                  as={Link}
                  to="/"
                >
                  CONTACT TPO
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <NavDropdown
              title="RESEARCH &amp; DEVELOPMENT"
              className="my-0"
              id="basic-nav-dropdown"
            >
              <div className="bg-secondaryColor text-white font-16 py-2">
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  DEAN RESEARCH
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  FACULTY DEVELOPMENT PROGRAM
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  PUBLICATIONS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  RESEARCH PROJECTS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  PATENTS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  CHAPTER/MONOGRAM
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  WORKSHOPS/SEMINAR
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  STUDENT PROJECTS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 py-2 dropdown-item dropdown-item-bottom"
                  as={Link}
                  to="/"
                >
                  STUDENT PROJECTS VIDEOS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-white font-regular font-12 pt-2 dropdown-item"
                  as={Link}
                  to="/"
                >
                  RESEARCH &amp; DEVELOPMENT
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <Nav.Link as={Link} to="/">
            <div className="text-secondaryColor font-regular font-12">COLLEGE OF PHARMACY</div>
            </Nav.Link>
            <Nav.Link as={Link} to="/admission">
            <div className="text-secondaryColor font-regular font-12 text-nowrap">ADMISSIONS PAGE</div>
            </Nav.Link>
          </Nav>
        </Navbar>
      </div>
    );
}

export default NavbarComponent2;
