import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../.././images/home/collegeLogo.png";
import { Link } from "react-router-dom";
import './navbar.css'

function NavbarComponent1() {
    return (
        <>
            <div className="text-light">
                <Navbar collapseOnSelect expand="lg" variant="dark" className="bg-primaryColor text-light">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse className="text-light" id="responsive-navbar-nav">
                        <Nav className="container font-12">
                            <NavDropdown title="NIRF DATA" className="bg-primaryColor font-semi-bold letter-spacing-2 font-12 text-light" id="collasible-nav-dropdown">
                                <NavDropdown.Item as={Link} to='/nirf/2018'>
                                    <div className="font-semi-bold font-14 dropdown-item-headnav">NIRF 2018</div>
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/nirf/2019'>
                                    <div className="font-semi-bold font-14 dropdown-item-headnav">NIRF 2019</div>
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/nirf/2020'>
                                    <div className="font-semi-bold font-14 dropdown-item-headnav">NIRF 2020</div>
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link className="text-light" as={Link} to="/library">
                                <div className="font-semi-bold letter-spacing-2 font-12 text-white">LIBRARY</div>
                            </Nav.Link>
                            <Nav.Link className="text-light" as={Link} to="/iic">
                            <div className="font-semi-bold letter-spacing-2 font-12 text-white">IIC</div>
                            </Nav.Link>
                            <Nav.Link className="text-light" as={Link} to="/step">
                            <div className="font-semi-bold letter-spacing-2 font-12 text-white">STEP</div>
                            </Nav.Link>
                            <Nav.Link className="text-light" as={Link} to="/dashboard">
                                <div className="font-semi-bold letter-spacing-2 font-12 text-white">DASHBOARD</div>
                            </Nav.Link>
                            <Nav.Link className="text-light" as={Link} to="/collegebrochure">
                                <div className="font-semi-bold letter-spacing-2 font-12 text-white">COLLEGE BROCHURE</div>
                            </Nav.Link>
                            <Nav.Link className="text-light" as={Link} to="/contactus">
                                <div className="font-semi-bold letter-spacing-2 font-12 text-white">CONTACT US</div>
                            </Nav.Link>
                            {/* <Nav.Link eventKey={2} as={Link} to="/memes">
                        Dank memes
                        </Nav.Link> */}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="container bg-backgroundColor mt-4 mb-4">
                    <div className="row">
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4 center">
                            <img src={Logo} alt="college_logo" className="img-fluid w-100" />
                        </div>
                        <div className="col-lg-10 col-md-9 col-sm-8 col-8 text-secondaryColor">
                            <p className="font-24 font-bold">JSS Academy of Technical Education</p>
                            <p className="font-16 font-semiBold">C-20/1, Sector-62, NOIDA.</p>
                        </div>
                    </div>
                    <div className="ml-5 text-secondaryColor"></div>
                </div>
                <hr className="w-100 hr" style={{olor: 'red'}}/>
            </div>
        </>
    );
}

export default NavbarComponent1;
